import React from "react"
import "./headsubhead.scss"
import ReactHTMLParser from "react-html-parser"

export default function HeadSubhead({
  heading,
  version = "v1",
  subheading,
  subHeadingTag = "p",
  ...props
}) {
  const SubHeadingElement = React.createElement(
    subHeadingTag,
    props,
    ReactHTMLParser(subheading)
  )

  return (
    <div className={`headsubhead ${version}`} {...props}>
      <h2 className="desc" dangerouslySetInnerHTML={{ __html: heading }}></h2>
      <SubHeadingElement.type {...SubHeadingElement.props} />
      {/* {subheading && <p dangerouslySetInnerHTML={{ __html: subheading }}></p>} */}
    </div>
  )
}
